import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Popover, List, ListItem, ListItemText } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import './HeaderFeatures.css';

const HeaderFeatures = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const externalLinkSymbol = '\u2197';
  return (
    <div>
        <ListItem className="header-menu-desktop" onClick={handleClick}>
            <ListItemText primary=" Features" />
            {open ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
        </ListItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav">
        <ListItem className="header-menu-desktop" component={Link}  to="/interviewsimulator-info">
                  <ListItemText primary="Features" />
                </ListItem>
                <ListItem className="header-menu-desktop" component={Link}  to="/howto">
                  <ListItemText primary="HowTo" />
                </ListItem>
                <ListItem className="menu-desktop" component={Link}  to="/faq">
                  <ListItemText primary="F.A.Q" />
                </ListItem>
          {/* Fügen Sie hier weitere Unterverbindungen hinzu */}
        </List>
      </Popover>
    </div>
  );
};

export default HeaderFeatures;
