import React, { lazy, Suspense } from 'react';
import { AuthProvider, useSession } from '@descope/react-sdk';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate  } from 'react-router-dom';
import Header from './components/Header';
import CookieConsent from "react-cookie-consent";
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './components/apps/locales/en/translation.json';
import translationDE from './components/apps/locales/de/translation.json';
import './app.css';
const Main = lazy(() => import('./components/Main'));
const Footer = lazy(() => import('./components/Footer'));
const Datenschutz = lazy(() => import('./components/Datenschutz'));
const Impressum = lazy(() => import('./components/Impressum'));
const VorstellungsgespraechUeben = lazy(() => import('./components/VorstellungsgespraechUeben'));
const About = lazy(() => import('./components/wichtiges/About'));
const ContactForm = lazy(() => import('./components/ContactForm'));
const Anmelden = lazy(() => import('./components/Anmelden'));
const FaqSite = lazy(() => import('./components/FaqSite'));
const FragenGenerator = lazy(() => import('./components/apps/fragen-generator/FragenGenerator'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const StripePricingTable = lazy(() => import('./components/wichtiges/StripePricingTable'));

const HowtoVideoSite = lazy(() => import('./components/HowtoVideoSite'));
const BlogPost = lazy(() => import('./components/articles/BlogPost'));
const BlogPage = lazy(() => import('./components/Blog'));
const SimulatorInfo = lazy(() => import('./components/SimulatorInfo'));
const SuccessPage = lazy(() => import('./components/wichtiges/SuccessPage'))


// Initialization
i18n
  .use(initReactI18next)
  .init({
    resources: {

      de: {
        translation: translationDE
      },
    },
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false
    }
  });

const RequireAuth = ({ children }) => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Wenn die Sitzung geladen wird, zeigen wir einen Ladebildschirm an
  if (isSessionLoading) {
      return <div>Laden...</div>;
  }
  
  // Wenn der Benutzer nicht authentifiziert ist, leiten wir ihn zur Anmeldeseite weiter
  if (!isAuthenticated) {
      return <Navigate to="/anmelden" state={{ from: location }} />;
  }

  // Wenn der Benutzer authentifiziert ist, zeigen wir die geschützte Komponente an
  return <>{children}</>;
};

const AppInner = () => {

  return (
    <Suspense fallback={<div>Laden...</div>}>
      <Header className="header-section d-none d-xl-block" />
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/anmelden" element={<Anmelden />} />
          <Route path="/preise" element={<StripePricingTable />} />
          <Route path="/vorstellungsgespraech-ueben" element={<VorstellungsgespraechUeben />} />
          <Route path="/faq" element={<FaqSite />} />
          <Route path="/bewerbungsfragen-generator" element={<FragenGenerator />} />
          <Route path="/interviewsimulator-info" element={<SimulatorInfo />} />
          <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
          <Route path="/success" element={<RequireAuth><SuccessPage /></RequireAuth>} />
          <Route path="/howto" element={<HowtoVideoSite />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogPost />} />
        </Routes>
        <CookieConsent>Diese Webseite nutzt keine Cookies.</CookieConsent>
      </div>
      <Footer />
    </Suspense>
  )
}


{/* The code below includes your Project ID (P2RnQ3w9YNRl9YqvkTYyMqNeTPXp) */}


//</GoogleOAuthProvider>
//</AuthProvider><GoogleOAuthProvider  clientId="836161505156-0s9gjlrie2vuchc7afl1fhh6hos2n64p.apps.googleusercontent.com">

const App = () => {

  return (
    <AuthProvider projectId="P2RnQ3w9YNRl9YqvkTYyMqNeTPXp">
      <I18nextProvider i18n={i18n}>
        <Router>
          
          <AppInner />
        
        </Router>
      </I18nextProvider>
    </AuthProvider>
  );
};

export default App;